import "Styles/Jvm/_Context.scss";
import "Styles/Eis/Context.js";

import $ from 'jquery';
import 'jquery-slick';
import 'Jvm/ViewportModule';
import CoreEvent from 'Jvm/CoreModule/Event/CoreEvent';
import 'Jvm/ScrollModule/Service/ScrollService';
import 'Jvm/TrackingModule/Service/JavascriptErrorTrackingService';
import { init as initViewFactory } from 'Jvm/CoreModule/Factory/ViewFactory';
import { init as initAppModule } from 'Eis/ApplicationModule';
import { init as initMainNavigationModule } from 'Eis/MainNavigationModule';
import { init as initMainNavigationLinkListModule } from 'Eis/MainNavigationLinkListModule';
import { init as initCartModule } from 'Eis/CartModule';
import { init as initBlogTileModule } from 'Eis/BlogTileModule';
import { init as initBraSizeModule } from 'Eis/BraSizeModule';
import { init as initClipboardModule } from 'Eis/ClipboardModule';
import { init as initColorPickerModule } from 'Eis/ColorPickerModule';
import { init as initCoverModule } from 'Eis/CoverModule';
import { init as initResponsiveImageModule } from 'Eis/ResponsiveImageModule';
import { init as initProductTileModule } from 'Eis/ProductTileModule';
import { init as initListicleTileModule } from 'Eis/ListicleTileModule';
import { init as initToggleModule } from 'Eis/ToggleModule';
import { init as initStickyElementsModule } from 'Eis/StickyElementsModule';
import { init as initVideoModule } from 'Eis/VideoModule';
import { init as initOverlayModule } from 'Eis/OverlayModule';
import { init as initPopoverModule } from 'Eis/PopoverModule';
import { init as initLinkHandlerModule } from 'Eis/LinkHandlerModule';
import { init as initProductDetailModule } from 'Eis/ProductDetailModule';
import { init as initFormFieldModule } from 'Eis/FormFieldModule';
import { init as initFlyoutModule } from 'Eis/FlyoutModule';
import { init as initSliderModule } from 'Eis/SliderModule';
import { init as initTableModule } from 'Eis/TableModule';
import { init as initSearchModule } from 'Eis/SearchModule';
import { init as initNotificationModule } from 'Eis/NotificationModule';
import { init as initInPageNavigationModule } from 'Eis/InPageNavigationModule';
import { init as initProductFinderModule } from 'Eis/ProductFinderModule';
import { init as initPresentFinderModule } from 'Eis/PresentFinderModule';
import { init as initTvModule } from 'Eis/TvModule';
import { init as initTopicModule } from 'Eis/TopicModule';
import { init as initPopupModule } from 'Eis/PopupModule';
import { init as initVoucherModule } from 'Eis/VoucherModule';
import { init as initGoogleMapsModule } from 'Eis/GoogleMapsModule';
import { init as initDiscountdownModule } from 'Eis/DiscountdownModule';
import { init as initPostContentModule } from 'Eis/PostContentModule';
import { init as initPromotionTileModule } from 'Eis/PromotionTileModule';
import { init as initLazyLoadModule } from 'Eis/LazyLoadModule';
import { init as initOptimizelyModule } from 'Eis/OptimizelyModule';
import { init as initWishlistModule } from 'Eis/WishlistModule';
import { init as initPinchZoomModule } from 'Eis/PinchZoomModule';
import { init as initLoginModule } from 'Eis/LoginModule';
import { init as initListingModule } from 'Eis/ListingModule';
import { init as initSubcategoryMenuModule } from 'Eis/SubcategoryMenuModule';
import { init as initInlineScrollerModule } from 'Eis/InlineScrollerModule';
import { init as initCheckoutModule } from 'Eis/CheckoutModule';
import { init as initMagazineTeasers } from 'Eis/MagazineTeasersModule';
import { init as initSearchboxModule } from 'Eis/SearchboxModule';
import { init as initPaypalModule } from 'Eis/PaypalModule';
import { init as initLandingPageManuals } from 'Eis/LandingPageManualsModule';

window.jQuery = $;
window.$ = $;

// TODO: not used?
//import { init as initScrollModule } from 'Jvm/ScrollModule';

// TODO: currently not in use?
// var MainNavigationBannerModule = require('Eis/MainNavigationBannerModule/Context');

export function init() {

    function prio1() {
        initViewFactory();
        initResponsiveImageModule();
        initPromotionTileModule();
        initListingModule();
    }

    function prio2() {
        initAppModule();
        initStickyElementsModule();
        initMainNavigationModule();
        initMainNavigationLinkListModule();
        initProductTileModule();
        initSliderModule();
        initLazyLoadModule();
        initCartModule();
        initListicleTileModule();
        initProductDetailModule();
        initTableModule();
        initOptimizelyModule();
        initSubcategoryMenuModule();
        initMagazineTeasers();
        initSearchboxModule();
        initPaypalModule();
    }

    function prio3() {
        initGoogleMapsModule();
        initToggleModule();
        initLinkHandlerModule();
        initCoverModule();
        initOverlayModule();
        initPopoverModule();
        initFlyoutModule();
        initPopupModule();
        initClipboardModule();
        initInlineScrollerModule();
    }

    function prio4() {
        initColorPickerModule();
        initBraSizeModule();
        initSearchModule();
        initProductFinderModule();
        initPresentFinderModule();
        initTvModule();
        initTopicModule();
        initVoucherModule();
        initDiscountdownModule();
        initPostContentModule();
        initWishlistModule();
        initCheckoutModule();
    }

    function prio5() {
        initBlogTileModule();
        initVideoModule();
        initFormFieldModule();
        initNotificationModule();
        initInPageNavigationModule();
        initPinchZoomModule();
        initLoginModule();
        initLandingPageManuals();
    }

    const queue = [prio1, prio2, prio3, prio4, prio5];

    const executeTimeout = 70;

    const execute = () => {
        queue.forEach((fn, index) => {
            setTimeout(fn, index  * executeTimeout);
        });
    }

    execute();

    // TODO: delete this if chrome lighthouse works again with sw.
    // sw causes lighthouse tests to fail
    if (location.href.indexOf('swoff') < 0 && 'serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js')
    }

    // TODO: not used?
    //initScrollModule();

    // TODO: currently not in use?
    // new MainNavigationBannerModule(ViewFactory, ModuleScopeConstant);
    //CoreEvent.trigger(CoreEvent.DOM_UPDATE, { moduleScope: document.body });
}

// TODO: remove require from qtm.js and remove this code
window.require = function(id) {
    if (id === 'Jvm/CoreModule/Event/CoreEvent') {
        return require("Jvm/CoreModule/Event/CoreEvent");
    } else if (id === 'underscore') {
        return require("underscore");
    } else if (id === 'Jvm/CoreModule/Utility/DataConfigUtility') {
        return require("Jvm/CoreModule/Utility/DataConfigUtility");
    } else if (id === 'Jvm/TrackingModule/Service/TrackingService') {
        return require('Jvm/TrackingModule/Service/TrackingService');
    } else if (id === 'Jvm/ViewportModule/Service/BreakpointService') {
        return require('Jvm/ViewportModule/Service/BreakpointService');
    } else if (id === 'Jvm/ViewportModule/Event/BreakpointEvent') {
        return require('Jvm/ViewportModule/Event/BreakpointEvent');
    } else if (id === 'Eis/CartModule/Event/CartEvent') {
        return require('Eis/CartModule/Event/CartEvent');
    }
};

if (!window.HTMLPictureElement) {
    import('picturefill');
}

$(init);
